// import React from "react";
// import { Container, Row, Col } from "react-bootstrap";
// import SwiperCore, { Pagination, Thumbs, Controller } from "swiper";
// import Swiper from "react-id-swiper";
// import BlockTitle from "./BlockTitle";

// import TestiQoute from "../assets/images/shapes/testi-qoute-1-1.png";

// import TestiImageOne from "../assets/images/resources/testi-1-1.jpg";
// import TestiImageTwo from "../assets/images/resources/testi-1-2.jpg";
// import TestiImageThree from "../assets/images/resources/testi-1-3.jpg";
// import TestiImage4 from "../assets/images/resources/testi-1-4.jpg";
// import TestiImage5 from "../assets/images/resources/testi-1-5.jpg";
// import TestiImage6 from "../assets/images/resources/testi-1-6.jpg";

// SwiperCore.use([Pagination, Thumbs, Controller]);

// const Testimonials = (props) => {
//   const paramsTwo = {
//     observer: true,
//     observeParents: true,
//     speed: 1400,
//     mousewheel: false,
//     autoplay: {
//       delay: 3000,
//       disableOnInteraction: false
//     },
//     pagination: {
//       el: ".testimonials-one__pagination-wrap .swiper-pagination",
//       clickable: true
//     }
//   };

//   const paramsOne = {
//     slidesPerView: 1,
//     spaceBetween: 0,
//     effect: "fade",
//     speed: 1400,
//     watchSlidesVisibility: true,
//     watchSlidesProgress: true,
//     loop: true,
//     autoplay: {
//       delay: 3000,
//       disableOnInteraction: false
//     }
//   };

//   return (
//     <section className="testimonials-one">
//       <Container>
//         <Row>
//           <Col xl={6}>
//             <div className="testimonials-one__thumb-carousel-wrap">
//               <div className="testimonials-one__icon">
//                 <div className="testimonials-one__icon-inner">
//                   <img src={TestiQoute} alt="awesome post" />
//                 </div>
//               </div>
//               <div className="testimonials-one__thumb-carousel">
//                 <Swiper {...paramsOne}>
//                   <div className="swiper-slide">
//                     <div className="testimonials-one__image">
//                       <img src={TestiImageOne} alt="awesome post" />
//                     </div>
//                   </div>
//                   <div className="swiper-slide">
//                     <div className="testimonials-one__image">
//                       <img src={TestiImageTwo} alt="awesome post" />
//                     </div>
//                   </div>
//                   <div className="swiper-slide">
//                     <div className="testimonials-one__image">
//                       <img src={TestiImageThree} alt="awesome post" />
//                     </div>
//                   </div>
//                   <div className="swiper-slide">
//                     <div className="testimonials-one__image">
//                       <img src={TestiImage4} alt="awesome post" />
//                     </div>
//                   </div>
//                   <div className="swiper-slide">
//                     <div className="testimonials-one__image">
//                       <img src={TestiImage5} alt="awesome post" />
//                     </div>
//                   </div>
//                   <div className="swiper-slide">
//                     <div className="testimonials-one__image">
//                       <img src={TestiImage6} alt="awesome post" />
//                     </div>
//                   </div>
//                 </Swiper>
//               </div>
//             </div>
//           </Col>
//           <Col xl={6} className="d-flex">
//             <div className="my-auto">
//               <BlockTitle
//                 textAlign="left"
//                 paraText="Chia sẻ"
//                 titleText={`Các cặp đôi Nối Thành Công`}
//               />
//               <div className="testimonials-one__carousel">
//                 <Swiper {...paramsTwo}>
//                   <div className="swiper-slide">
//                     <div className="testimonials-one__single">
//                       <p className="testimonials-one__text">
//                       "Trước khi dùng iudi, mình không nghĩ sẽ tìm được một người hiểu và đồng cảm với mình. Nhưng giờ đây, mình đã tìm được một nửa thật sự, một người luôn bên cạnh sẻ chia mọi niềm vui, nỗi buồn. Cảm ơn iudi đã giúp mình mở ra cánh cửa tình yêu!" 🌹💑                      </p>

//                       <h3 className="testimonials-one__title">Phạm Tuyên & Nguyễn Vân</h3>
//                     </div>
//                   </div>
//                   <div className="swiper-slide">
//                     <div className="testimonials-one__single">
//                       <p className="testimonials-one__text">
//                       "Mình từng nghĩ ứng dụng hẹn hò chỉ là nơi vui chơi, nhưng iudi hoàn toàn khác. Đây là cộng đồng kết nối an toàn, văn minh, giúp mình tìm được một người bạn đời thực sự. Những cuộc trò chuyện chân thành, những lần gặp gỡ đáng nhớ, tất cả đều bắt đầu từ iudi." 🌟                      </p>

//                       <h3 className="testimonials-one__title">Đức Tuân & Hải Yến</h3>
//                     </div>
//                   </div>
//                   <div className="swiper-slide">
//                     <div className="testimonials-one__single">
//                       <p className="testimonials-one__text">
//                       "Từ lúc sử dụng iudi, mình nhận ra việc hẹn hò không còn khó khăn và nhàm chán như trước nữa. Chỉ trong vài bước đơn giản, mình đã kết nối với nhiều người thú vị. Và rồi, người đặc biệt đã xuất hiện – người đã làm cuộc sống mình thay đổi theo cách tuyệt vời nhất." 💕                      </p>

//                       <h3 className="testimonials-one__title">Văn Thủy & Tú Anh</h3>
//                     </div>
//                   </div>
//                   <div className="swiper-slide">
//                     <div className="testimonials-one__single">
//                       <p className="testimonials-one__text">
//                       "Mình đã từng hoài nghi về ứng dụng hẹn hò, nhưng iudi đã thay đổi quan điểm của mình. Các kết nối trên đây đều chân thành, nghiêm túc. Và chính nhờ sự chân thành đó, mình đã tìm được người đặc biệt, người khiến trái tim mình đập rộn ràng trở lại." 💞🌈         </p>            
//                         <h3 className="testimonials-one__title"> Thanh Lâm & Ngọc Anh</h3>
//                     </div>
//                   </div>
//                   <div className="swiper-slide">
//                     <div className="testimonials-one__single">
//                       <p className="testimonials-one__text">
// "Mình đã tải iudi về với chút hy vọng và tò mò. Không ngờ rằng, hành trình thoát 'ế' lại dễ dàng và thú vị đến vậy. Những cuộc trò chuyện vui vẻ, sự quan tâm chân thành đã dẫn đến một mối quan hệ tuyệt đẹp mà mình luôn ao ước. Giờ đây, mình có thể tự hào nói rằng: iudi thật sự đã thay đổi cuộc đời mình!" 🌻🌟</p>
//                       <h3 className="testimonials-one__title">Nguyễn Bá T & Võ Thu H</h3>
//                     </div>
//                   </div>
//                   <div className="swiper-slide">
//                     <div className="testimonials-one__single">
//                       <p className="testimonials-one__text">
//                       "Từ lúc sử dụng iudi, mình nhận ra việc hẹn hò không còn khó khăn và nhàm chán như trước nữa. Chỉ trong vài bước đơn giản, mình đã kết nối với nhiều người thú vị. Và rồi, người đặc biệt đã xuất hiện – người đã làm cuộc sống mình thay đổi theo cách tuyệt vời nhất." 💕                      </p>

//                       <h3 className="testimonials-one__title">Trung Quân & Bảo Chiêu</h3>
//                     </div>
//                   </div>
//                 </Swiper>
//                 <div className="testimonials-one__pagination-wrap">
//                   <div className="swiper-pagination"></div>
//                 </div>
//               </div>
//             </div>
//           </Col>
//         </Row>
//       </Container>
//     </section>
//   );
// };

// export default Testimonials;



// import React from "react";
// import { Container, Row, Col } from "react-bootstrap";
// import SwiperCore, { Pagination, Autoplay } from "swiper";
// import Swiper from "react-id-swiper";
// import BlockTitle from "./BlockTitle";

// import TestiQoute from "../assets/images/shapes/testi-qoute-1-1.png";

// import TestiImageOne from "../assets/images/resources/testi-1-1.jpg";
// import TestiImageTwo from "../assets/images/resources/testi-1-2.jpg";
// import TestiImageThree from "../assets/images/resources/testi-1-3.jpg";
// import TestiImage4 from "../assets/images/resources/testi-1-4.jpg";
// import TestiImage5 from "../assets/images/resources/testi-1-5.jpg";
// import TestiImage6 from "../assets/images/resources/testi-1-6.jpg";

// SwiperCore.use([Pagination, Autoplay]);

// const Testimonials = () => {
//   const params = {
//     slidesPerView: 1,
//     spaceBetween: 0,
//     speed: 1400,
//     loop: true,
//     autoplay: {
//       delay: 3000,
//       disableOnInteraction: false,
//     },
//     pagination: {
//       el: ".testimonials-one__pagination-wrap .swiper-pagination",
//       clickable: true,
//     },
//   };

//   return (
//     <section className="testimonials-one">
//       <Container>
//         <Row>
//           <Col xl={12} className="d-flex">
//             <div className="my-auto">
//               <BlockTitle
//                 textAlign="left"
//                 paraText="Chia sẻ"
//                 titleText={`Các cặp đôi Nối Thành Công`}
//               />
//               <div className="testimonials-one__carousel">
//                 <Swiper {...params}>
//                   <div className="swiper-slide">
//                     <Row>
//                       <Col xl={6}>
//                         <div className="testimonials-one__image">
//                           <img src={TestiImageOne} alt="awesome post" />
//                         </div>
//                       </Col>
//                       <Col xl={6} className="d-flex align-items-center">
//                         <div className="testimonials-one__single">
//                           <p className="testimonials-one__text">
//                             "Trước khi dùng iudi, mình không nghĩ sẽ tìm được một người hiểu và đồng cảm với mình. Nhưng giờ đây, mình đã tìm được một nửa thật sự, một người luôn bên cạnh sẻ chia mọi niềm vui, nỗi buồn. Cảm ơn iudi đã giúp mình mở ra cánh cửa tình yêu!" 🌹💑
//                           </p>
//                           <h3 className="testimonials-one__title">Phạm Tuyên & Nguyễn Vân</h3>
//                         </div>
//                       </Col>
//                     </Row>
//                   </div>

//                   <div className="swiper-slide">
//                     <Row>
//                       <Col xl={6}>
//                         <div className="testimonials-one__image">
//                           <img src={TestiImageTwo} alt="awesome post" />
//                         </div>
//                       </Col>
//                       <Col xl={6} className="d-flex align-items-center">
//                         <div className="testimonials-one__single">
//                           <p className="testimonials-one__text">
//                             "Mình từng nghĩ ứng dụng hẹn hò chỉ là nơi vui chơi, nhưng iudi hoàn toàn khác. Đây là cộng đồng kết nối an toàn, văn minh, giúp mình tìm được một người bạn đời thực sự. Những cuộc trò chuyện chân thành, những lần gặp gỡ đáng nhớ, tất cả đều bắt đầu từ iudi." 🌟
//                           </p>
//                           <h3 className="testimonials-one__title">Đức Tuân & Hải Yến</h3>
//                         </div>
//                       </Col>
//                     </Row>
//                   </div>

//                   {/* Repeat similar structure for other slides */}
//                   <div className="swiper-slide">
//                     <Row>
//                       <Col xl={6}>
//                         <div className="testimonials-one__image">
//                           <img src={TestiImageThree} alt="awesome post" />
//                         </div>
//                       </Col>
//                       <Col xl={6} className="d-flex align-items-center">
//                         <div className="testimonials-one__single">
//                           <p className="testimonials-one__text">
//                             "Từ lúc sử dụng iudi, mình nhận ra việc hẹn hò không còn khó khăn và nhàm chán như trước nữa. Chỉ trong vài bước đơn giản, mình đã kết nối với nhiều người thú vị. Và rồi, người đặc biệt đã xuất hiện – người đã làm cuộc sống mình thay đổi theo cách tuyệt vời nhất." 💕
//                           </p>
//                           <h3 className="testimonials-one__title">Văn Thủy & Tú Anh</h3>
//                         </div>
//                       </Col>
//                     </Row>
//                   </div>
//                 </Swiper>

//                 <div className="testimonials-one__pagination-wrap">
//                   <div className="swiper-pagination"></div>
//                 </div>
//               </div>
//             </div>
//           </Col>
//         </Row>
//       </Container>
//     </section>
//   );
// };

// export default Testimonials;



import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import SwiperCore, { Pagination, Autoplay } from "swiper";
import Swiper from "react-id-swiper";
import BlockTitle from "./BlockTitle";
 import TestiQoute from "../assets/images/shapes/testi-qoute-1-1.png";
import TestiImageOne from "../assets/images/resources/testi-1-1.jpg";
import TestiImageTwo from "../assets/images/resources/testi-1-2.jpg";
import TestiImageThree from "../assets/images/resources/testi-1-3.jpg";
import TestiImage4 from "../assets/images/resources/testi-1-4.jpg";
import TestiImage5 from "../assets/images/resources/testi-1-5.jpg";
import TestiImage6 from "../assets/images/resources/testi-1-6.jpg";

SwiperCore.use([Pagination, Autoplay]);

const Testimonials = () => {
  const params = {
    slidesPerView: 1,
    spaceBetween: 0,
    speed: 1400,
    loop: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    pagination: {
      el: ".testimonials-one__pagination-wrap .swiper-pagination",
      clickable: true,
    },
  };

  return (
    <section className="testimonials-one">
      <Container>
        <Row>
          <Col xl={12} className="d-flex">
            <div className="my-auto">
              <BlockTitle
                textAlign="left"
                paraText="Chia sẻ"
                titleText={`Các cặp đôi nối thành công`}
              />
              
              <div className="testimonials-one__carousel">
                <Swiper {...params}>
                  {/* Slide 1 */}
                  <div className="swiper-slide">
                    <Row>
                      <Col xl={6}>
                        {/* <div className="testimonials-one__image">
                          <img src={TestiImageOne} alt="awesome post" />
                        </div> */}
                        <div className="testimonials-one__image" style={{ overflow: 'hidden', borderRadius: '15px' }}>
  <img
    src={TestiImageOne}
    alt="awesome post"
    style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
  />
</div>
                      </Col>
                      <Col xl={6} className="d-flex align-items-center">
                        <div className="testimonials-one__single">
                          <p className="testimonials-one__text">
                          Cặp đôi nối thành công khi tham gia hẹn hò tại Nối. Chuyện tình của hai bạn ý rất nhiều điều bất ngờ và thú vị. Cô nàng làm trong lĩnh vực kinh doanh, nên là một mẫu phụ nữ cá tính, năng động, bản lĩnh và giỏi giang. Còn anh chàng là một kỹ thuật viên bên truyền hình SCTV, một mẫu đàn ông lịch sự, chững chạc.                          </p>
                          <h3 className="testimonials-one__title">Thành Luân & Hoàng Vân</h3>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  
                  {/* Slide 2 */}
                  <div className="swiper-slide">
                    <Row>
                      <Col xl={6}>
                        <div className="testimonials-one__image">
                          <img src={TestiImageTwo} alt="awesome post" />
                        </div>
                      </Col>
                      <Col xl={6} className="d-flex align-items-center">
                        <div className="testimonials-one__single">
                          <p className="testimonials-one__text">
                          Hôm nay em là cô dâu, hôm nay em mặc chiếc váy cưới bước theo chồng, chúc em sẽ mãi hạnh phúc, vui vẻ bên người em đã chọn. Cuối cùng thì NOBITA cũng đã cưới XUKA. Hai bạn quen nhau, và hẹn hò khi cùng tham gia Nối, cả hai đều là những gương mặt khá thân quen trong cộng đồng.                          </p>
                          <h3 className="testimonials-one__title">Bi Trần & Thanh Hà</h3>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  {/* Slide 3 */}
                  <div className="swiper-slide">
                    <Row>
                      <Col xl={6}>
                        <div className="testimonials-one__image">
                          <img src={TestiImageThree} alt="awesome post" />
                        </div>
                      </Col>
                      <Col xl={6} className="d-flex align-items-center">
                        <div className="testimonials-one__single">
                          <p className="testimonials-one__text">
                          Thế nào là "yêu đúng người - đúng thời điểm" thì cặp đôi này là một minh chứng nhé. Yêu bao lâu thì cưới?
                          Đến một độ tuổi nhất định, có lẽ bạn sẽ biết câu trả lời chính xác cho câu hỏi này. Bạn yêu bao lâu không quan trọng. Quan trọng là bạn có “cảm giác hôn nhân” với người đó chưa.                          </p>
                          <h3 className="testimonials-one__title">Phan Hoàng & Michelle Nguyễn</h3>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  {/* Slide 4 */}
                  <div className="swiper-slide">
                    <Row>
                      <Col xl={6}>
                        <div className="testimonials-one__image">
                          <img src={TestiImage4} alt="awesome post" />
                        </div>
                      </Col>
                      <Col xl={6} className="d-flex align-items-center">
                        <div className="testimonials-one__single">
                          <p className="testimonials-one__text">
                          Cả hai bạn đều tham gia Nối và hai bạn đã gặp nhau trong một chương trình kết nối tổ chức hồi cuối năm 2023. Sau 6 tháng yêu nhau và tìm hiểu, thì hai bạn đã về chung nhà. Đám cưới hai bạn được tổ chức vào ngày 21/07/24 vừa qua tại TP.HCM.                          </p>
                          <h3 className="testimonials-one__title">Phạm Tuyên & Nguyễn Vân</h3>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  {/* Slide 5 */}
                  <div className="swiper-slide">
                    <Row>
                      <Col xl={6}>
                        <div className="testimonials-one__image">
                          <img src={TestiImage5} alt="awesome post" />
                        </div>
                      </Col>
                      <Col xl={6} className="d-flex align-items-center">
                        <div className="testimonials-one__single">
                          <p className="testimonials-one__text">
                          Rồi cũng có người nguyện nắm lấy tay em, cùng em từ đây...về sau...và mãi mãi. Anh chàng IT và Cô Giáo may mắn" - Trend mới dành cho các bạn FA của nhà Nối mình khi tìm người yêu đây rồi. BQT Nối trân trọng gửi lời chúc mừng hạnh phúc đến hai bạn. Hãy yêu thương nhau thật nhiều và sống thật hạnh phúc nhé.                          </p>
                          <h3 className="testimonials-one__title">Lâm Nguyễn Phong & Ngọc Huỳnh Tạ</h3>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  {/* Slide 6 */}
                  <div className="swiper-slide">
                    <Row>
                      <Col xl={6}>
                        <div className="testimonials-one__image">
                          <img src={TestiImage6} alt="awesome post" />
                        </div>
                      </Col>
                      <Col xl={6} className="d-flex align-items-center">
                        <div className="testimonials-one__single">
                          <p className="testimonials-one__text">
                          Thật sự thì…không có một ai sinh ra là dành sẵn cho bạn. Đừng tìm kiếm trong mịt mờ vô vọng nữa. Mỗi người là một cá thể hoàn toàn riêng biệt. Trong tình yêu gặp được nhau là "DUYÊN", đi được cùng nhau đó là “NỖ LỰC” và sự vun vén khéo léo, biết dung hòa những điểm khác biệt. Đây là thông điệp Nối muốn gửi đến các bạn thành viên khác đang trên hành trình đi tìm một nửa của mình.                          </p>
                          <h3 className="testimonials-one__title">Thanh Tuấn & Mỹ Phượng</h3>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Swiper>

                <div className="testimonials-one__pagination-wrap">
                  <div className="swiper-pagination"></div>
                </div>
              </div>
            </div>
            
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Testimonials;
