

import React from "react";
import BlockTitle from "./BlockTitle";
import like from "../assets/images/resources/like.png";
import package1 from "../assets/images/resources/package.png";
import resume from "../assets/images/resources/resume.png";
import onlinedating from "../assets/images/resources/online-dating.png";

const Services = () => {
  return (
    <section className="service-one" id="features">
      <div className="container">
        <BlockTitle
          textAlign="center"
          paraText="HÃY YÊU NHAU ĐI – Một Cộng Đồng Kết Nối An Toàn, Văn Minh và Nghiêm Túc"
          titleText={`4 Bước để yêu`}
        />
        <div className="row">
          {/* Bước 1 */}
          <div className="col-lg-3 col-md-6 col-12 d-flex align-items-center justify-content-center mb-4">
            <div 
              className="service-one__single h-100 d-flex flex-column align-items-center justify-content-center text-center"
              style={{
                minHeight: '300px',
                padding: '20px',
                position: 'relative',
                zIndex: 1,
                overflow: 'hidden', // Add overflow hidden to avoid content spilling
              }}
            >
              <div className="service-one__icon" style={{ zIndex: 2 }}>
                <img
                  src={like}
                  className="wow fadeInUp"
                  data-wow-duration="1500ms"
                  alt="Bước 1"
                  style={{ width: '70%', maxWidth: '150px', height: 'auto' }}
                />
              </div>
              <h3 style={{ zIndex: 2 }}>Bước 1</h3>
              <p style={{ zIndex: 2 }}>Truy cập vào ứng dụng</p>
            </div>
          </div>

          {/* Bước 2 */}
          <div className="col-lg-3 col-md-6 col-12 d-flex align-items-center justify-content-center mb-4">
            <div 
              className="service-one__single h-100 d-flex flex-column align-items-center justify-content-center text-center"
              style={{
                minHeight: '300px',
                padding: '20px',
                position: 'relative',
                zIndex: 1,
                overflow: 'hidden',
              }}
            >
              <div className="service-one__icon" style={{ zIndex: 2 }}>
                <img
                  src={resume}
                  className="wow fadeInUp"
                  data-wow-duration="1500ms"
                  alt="Bước 2"
                  style={{ width: '70%', maxWidth: '150px', height: 'auto' }}
                />
              </div>
              <h3 style={{ zIndex: 2 }}>Bước 2</h3>
              <p style={{ zIndex: 2 }}>Đăng ký hồ sơ thành viên</p>
            </div>
          </div>

          {/* Bước 3 */}
          <div className="col-lg-3 col-md-6 col-12 d-flex align-items-center justify-content-center mb-4">
            <div 
              className="service-one__single h-100 d-flex flex-column align-items-center justify-content-center text-center"
              style={{
                minHeight: '300px',
                padding: '20px',
                position: 'relative',
                zIndex: 1,
                overflow: 'hidden',
              }}
            >
              <div className="service-one__icon" style={{ zIndex: 2 }}>
                <img
                  src={package1}
                  className="wow fadeInUp"
                  data-wow-duration="1500ms"
                  alt="Bước 3"
                  style={{ width: '70%', maxWidth: '150px', height: 'auto' }}
                />
              </div>
              <h3 style={{ zIndex: 2 }}>Bước 3</h3>
              <p style={{ zIndex: 2 }}> Mua gói dịch vụ để sử dụng</p>
            </div>
          </div>

          {/* Bước 4 */}
          <div className="col-lg-3 col-md-6 col-12 d-flex align-items-center justify-content-center mb-4">
            <div 
              className="service-one__single h-100 d-flex flex-column align-items-center justify-content-center text-center"
              style={{
                minHeight: '300px',
                padding: '20px',
                position: 'relative',
                zIndex: 1,
                overflow: 'hidden',
              }}
            >
              <div className="service-one__icon" style={{ zIndex: 2 }}>
                <img
                  src={onlinedating}
                  className="wow fadeInUp"
                  data-wow-duration="1500ms"
                  alt="Bước 4"
                  style={{ width: '70%', maxWidth: '150px', height: 'auto' }}
                />
              </div>
              <h3 style={{ zIndex: 2 }}>Bước 4</h3>
              <p style={{ zIndex: 2 }}>Chọn hồ sơ phù hợp và kết nối</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
