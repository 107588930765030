import React from "react";
import { Container } from "react-bootstrap";
import BlockTitle from "./BlockTitle";
import AccordionItem from "./AccordionItem";
import faqBG from "../assets/images/shapes/faq-bg-1-1.png";
import appImage1 from "../assets/images/resources/henho1.png";
const FAQ = (props) => {
  return (
    <section className="faq-one">
      <img src={faqBG} className="faq-one__bg-shape-1" alt="awesome post" />
      <Container>
        <BlockTitle
          textAlign="center"
          paraText="Vì sao"
          titleText={` Nên chọn "iudi"?`}
        />
        <div className="item1" style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end', position: 'relative', zIndex: 10, marginBottom:'20px',  }}>
  <img src={appImage1} alt="awesome post" style={{ width: '100%', maxWidth: '1000px', height: 'auto' }} />
</div>
        <div className="accrodion-grp faq-accrodion">
          <AccordionItem
            title="Bảo mật và an toàn  với nền tảng Zalo"
            content={
            
            <>
            <p>• iudi tận dụng sức mạnh bảo mật từ Zalo, giúp bạn đăng ký và tham gia với tài khoản Zalo cá nhân.</p>
           
            <p>• Điều này không chỉ giúp bảo vệ quyền riêng tư của bạn mà còn giúp cập nhật các kết nối và tương tác một cách nhanh chóng, tiện lợi.</p>
          </>
            
  }
            
            status={true}
          />
          <AccordionItem
            title="100% thành viên thật - Tài khoản thật tại app"
            
            content={
            
              <>
              <p>• Chúng tôi cam kết 100% thành viên thật. Mỗi người tham gia đều được xác thực và xác minh giấy tờ tùy thân, mang đến sự tin cậy  trong từng kết nối.</p>
             
              <p>• Không còn lo lắng về các tài khoản ảo hay thiếu trung thực.</p>
            </>
              
    }
            
            
            
            
            
            status={false}
          />
        <AccordionItem
  title="Tính năng bảo mật đỉnh cao"
  content={
    <>
      <p>• Thông tin cá nhân như địa chỉ, số điện thoại, CCCD hay nick Facebook của bạn sẽ được bảo mật </p>
      <p>• Những hình ảnh cá nhân chỉ hiển thị cho các thành viên đã đăng ký và được kiểm duyệt bởi Ban Quản Trị.</p>
      <p>• Bạn hoàn toàn kiểm soát được ai có quyền xem thông tin và hình ảnh của mình.</p>
    </>
  }
  status={false}
/>
          <AccordionItem
            title="Hỗ trợ 24/7"
            
            
            
            content={
            
              <>
              <p>• Đội ngũ nhân viên hỗ trợ online 24/7, giải đáp mọi thắc mắc và xử lý các vấn đề phát sinh một cách kịp thời.</p>
             
              <p>• Bạn có thể yên tâm trải nghiệm và tìm kiếm tình yêu mà không phải lo lắng về bất kỳ vấn đề kỹ thuật nào.</p>
            </>
              
    }
            
            status={false}
          />
         
        </div>
      </Container>
    </section>
  );
};

export default FAQ;
