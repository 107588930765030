import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import BannerShape from "../assets/images/shapes/banner-shape-1-1.png";
import BannerBG from "../assets/images/resources/banner-image-1-1.jpg";
import BannerMoc from "../assets/images/resources/banner-moc-1-1.png";
import qrCodeImage from '../assets/images/qr.jpg';
import CtaShape2 from "../assets/images/shapes/cta-1-shape-2.png";

import Image1 from "../assets/images/resources/henho1.png"; // Add your image imports here
import Image2 from "../assets/images/resources/henho2.jpg"; // Add your image imports here
import Image3 from "../assets/images/resources/henho3.jpg"; // Add your image imports here
import Image4 from "../assets/images/resources/henho4.jpg"; // Add your image imports here
import Image5 from "../assets/images/resources/henho5.jpg"; // Add your image imports here
import Image6 from "../assets/images/resources/henho6.jpg"; // Add your image imports here
import Image7 from "../assets/images/resources/henho7.jpg"; // Add your image imports here
import Image8 from "../assets/images/resources/henho8.jpg"; // Add your image imports here
import Image9 from "../assets/images/resources/henho9.jpg"; // Add your image imports here
import Image10 from "../assets/images/resources/henho10.jpg"; // Add your image imports here
import note1 from "../assets/images/resources/note1.jpg"; // Add your image imports here
import note2 from "../assets/images/resources/note2.jpg"; // Add your image imports here
import note3 from "../assets/images/resources/note3.jpg"; // Add your image imports here
const Banner = () => {
  const [open, setOpen] = useState({
    isOpen: false
  });
  const openModal = () => {
    setOpen({
      isOpen: true
    });
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <section className="banner-one" id="home">
      
      <img
        src={BannerShape}
        className="banner-one__bg-shape-1"
        alt="awesome post"
      />
     
      <div
        className="banner-one__bg"
        style={{ backgroundImage: `url(${BannerBG})` }}
      ></div>
       <img src={CtaShape2} className="cta-one__bg-shape-3" alt="awesome post" />
      <div className="container">
        {/* <ModalVideo
          channel="youtube"
          isOpen={open.isOpen}
          videoId="Kl5B6MBAntI"
          onClose={() => setOpen({ isOpen: false })}
        />
        <div
          onClick={openModal}
          onKeyDown={openModal}
          role="button"
          tabIndex="0"
          className="banner-one__video video-popup"
        >
          <i className="fa fa-play"></i>
        </div> */}
       
        <div className="banner-one__moc">
          <img
            src={BannerMoc}
            className="wow fadeInUp"
            data-wow-duration="1500ms"
            alt="awesome post"
          />
        </div> 
        <div className="row">
          <div className="col-lg-7">
            <div className="banner-one__content">
              {/* <form
                className="banner-one__mc-form mc-form"
                data-url="MAILCHIMP__POPUP__FORM__URL"
              >
                <input type="text" name="email" placeholder="Email address" />
                <button type="submit" className="thm-btn banner-one__mc-btn">
                  <span>Free Trial</span>
                </button>
              </form> */}
              {/* <div className="mc-form__response"></div> */}
              <h3>
              Tìm Kiếm Tình Yêu Chân Thực <br />Cùng Ứng Dụng Hẹn Hò "iudi.app"
                
              </h3>
              <p>
              Chào mừng bạn đến với iudi, ứng dụng hẹn hò duy nhất tại thời<br /> điểm này  ở  Việt Nam hoạt động trên nền tảng Zalo.  <br /> Được phát triển và điều hành bởi Nối Group, thương hiệu đã hoạt động hơn<br /> 10 năm trong lĩnh vực kết nối  những  người độc thân.            
               <br /> Đây không chỉ là một app hẹn hò, mà là nơi kết nối những tâm hồn <br /> đồng điệu với sứ mệnh xây dựng mối quan hệ bền vững và ý nghĩa. {" "}
                
              </p>
              
            <div style={{ display: 'flex', justifyContent: 'center', listStyleType: 'none', padding: 0 }}>
  <div>
    <img
      src={qrCodeImage}
      alt="QR Code"
      style={{ width: '300px', height: '300px', objectFit: 'contain' }}
    />
  </div>
 
</div>
<div style={{ textAlign: 'center' }}>
{/* <Button variant="primary" onClick={handleShow} className="thm-btn cta-one__btn">
                <span>Truy cập app</span>
              </Button> */}
              <Button variant="primary" onClick={() => window.location.href='https://zalo.me/s/335985251439518648'} className="thm-btn cta-one__btn">
  <span>Truy cập app</span>
</Button>
</div>
            </div>
          </div>
        </div>
      </div>
       {/* Modal */}
       <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Hướng dẫn sử dụng</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: '600px', overflowY: 'auto' }}>
         
        <div style={{ display: 'flex', justifyContent: 'center' }}>
  <img src={note1} alt="Hướng dẫn truy cập Zalo" style={{ width: '30%', margin: '20px 0' }} />
</div>
        <h4>1. Hướng dẫn truy cập app từ Zalo</h4>
          <p>
          Truy cập ứng dụng Zalo, nhập từ khóa “iudi” trên thanh tìm kiếm hoặc trong mục “Khám phá”, sau đó nhấp vào kết quả mini app “Ứng dụng hẹn hò – iudi” được hiển thị trên màn hình.
          </p>
          <img src={Image1} alt="Hướng dẫn truy cập Zalo" style={{ width: '100%', margin: '20px 0' }} />
          <div style={{ display: 'flex', justifyContent: 'center' }}>
  <img src={note2} alt="Hướng dẫn truy cập Zalo" style={{ width: '30%', margin: '20px 0' }} />
</div>
          <h4>2. Hướng dẫn ghim app ra màn hình điện thoại</h4>
          <p>
            Để thuận tiện cho các lần truy cập về sau một cách nhanh chóng, bạn hãy tạo phim tắt ghim trực tiếp app ra màn hình chính điện thoại của mình.
          </p>
          <ol>
            <li>
              <strong>Bước 1:</strong> Vào trang chủ của app iudi chọn menu có 3 dấu chấm kích vào đó.

            </li>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
  <img src={Image2} alt="Hướng dẫn truy cập Zalo" style={{ width: '50%', margin: '20px 0' }} />
</div>


            <li>
              <strong>Bước 2:</strong> Một bảng điều khiển menu chức năng sẽ hiện ra và bạn hãy chọn mục “Tạo phím tắt” để ghim app iudi ra màn hình điện thoại của mình.
            </li>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
  <img src={Image3} alt="Hướng dẫn truy cập Zalo" style={{ width: '50%', margin: '20px 0' }} />
</div>


<h4>LƯU Ý:</h4>

<h5>1. Với hệ điều hành IOS </h5>

<p>- Điện thoại iphone/ipad của bạn phải đặt safari là trình duyệt mặc định</p>

<p>- Hoặc bạn có thể thao tác thủ công tự mở trình duyệt safari lên, sau đó copy đường dẫn này: (https://zalo.me/s/335985251439518648/) 

đây là đường dẫn của app iudi và sau đó paste vào thanh địa chỉ website trên safari là được.</p>

<p>- Làm theo video hướng dẫn để hoàn tất ghim ứng dụng ra màn hình chính điện thoại</p>

<div>
    
  </div>
  <div style={{ display: 'flex', justifyContent: 'center' }}>
  <img src={Image4} alt="Hướng dẫn truy cập Zalo" style={{ width: '50%', margin: '20px 0' }} />
</div>





<p>- Mọi người có thể chính sửa lại tên “Ứng dụng hẹn hò - iudi” thành tên “iudi” hoặc "App iudi" để khi hiện trên màn hình điện thoại được đẹp hơn.</p>


 <div style={{ display: 'flex', justifyContent: 'center' }}>
  <img src={Image5} alt="Hướng dẫn truy cập Zalo" style={{ width: '50%', margin: '20px 0' }} />
</div>


<div style={{ display: 'flex', justifyContent: 'center' }}>
  <img src={Image6} alt="Hướng dẫn truy cập Zalo" style={{ width: '50%', margin: '20px 0' }} />
</div>



<h5>2. Với hệ điều hành Android </h5>

<p>- Đối với một số dòng máy, khi bạn bấm vào “Tạo phím tắt” sẽ tự động ghim app ra màn hình chính của điện thoại.</p>

<p>- Nhưng cũng có các dòng máy do tuỳ biến của một số hãng, ứng dụng Zalo cần được người dùng chủ động cấp quyền để có thể thêm Shortcut/ghim app ra màn hình chính.</p>

<p>Các bước để chủ động cấp quyền bao gồm:</p>

<p>1 - Nhấn giữ biểu tượng ứng dụng Zalo.</p>

<p>2 - Từ menu popup, chọn App Info/Thông tin ứng dụng</p>

<p>3 - Truy cập mục Quyền ứng dụng/Permissions
 bật quyền Desktop shortcuts/Lối tắt màn hình chính</p>


 <div style={{ display: 'flex', justifyContent: 'center' }}>
  <img src={Image7} alt="Hướng dẫn truy cập Zalo" style={{ width: '50%', margin: '20px 0' }} />
</div>
  
<div style={{ display: 'flex', justifyContent: 'center' }}>
  <img src={Image8} alt="Hướng dẫn truy cập Zalo" style={{ width: '50%', margin: '20px 0' }} />
</div>
  
<div style={{ display: 'flex', justifyContent: 'center' }}>
  <img src={Image9} alt="Hướng dẫn truy cập Zalo" style={{ width: '50%', margin: '20px 0' }} />
</div>

<p>(Lưu ý: Giao diện thiết lập cũng như các bước có thể khác nhau, tuỳ thuộc vào phiên bản Android tuỳ biến đang được sử dụng ở các dòng máy khác nhau)</p>
          </ol>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
  <img src={note3} alt="Hướng dẫn truy cập Zalo" style={{ width: '30%', margin: '20px 0' }} />
</div>
<h5>Bảo mật hình ảnh 2 lớp</h5> 
           <ul>

<li>Thành viên khi chưa đăng ký tài khoản (khách truy cập) khi vào app không thể xem được hình ảnh của bạn. </li>

<li>Thành viên đã có tài khoản và đã được BQT xét duyệt chính thức tham gia app, sẽ xem được hình ảnh của bạn. Nhưng dưới dạng hình ảnh bị làm mờ đi.</li>

<li>Và khi cả 2 bạn cùng bấm quan tâm nhau, lúc đó người kia mới xem được hình ảnh rõ nét của bạn.</li>
</ul>


<h5>Công cụ tìm kiếm chuyên sâu và chính xác</h5>

<li>Tùy vào mỗi gói hồ sơ đăng ký sử dụng tại app, mà bạn sẽ nhận được các quyền lợi tìm kiếm theo các hạng mục tiêu chí riêng.</li>

<li>Công cụ tìm kiếm giúp chọn lựa, sàng lọc nhóm đối tượng phù hợp theo mong muốn của bạn như: Tìm theo nơi ở, tìm theo quê quán, tìm theo tôn giáo, tìm theo nghề nghiệp…</li>



<h5>Chát qua Zalo chưa biết số điện thoại</h5>

<li>Khi hai bạn cùng bấm “Quan tâm” nhau, cả 2 sẽ được kết nối chát qua Zalo cá nhân. Nhưng hoàn toàn chưa biết số điện thoại của nhau.</li>

<li> Điều này giúp bạn chủ động kiểm soát các thông tin riêng tư của mình. Quyết định chia sẻ với ai và không chia sẻ với ai.</li>



<h5>Tính năng tự “Tự Ẩn hồ sơ” của bạn</h5>

<li>Tự ẩn hồ sơ của bạn không hiển thị công khai trên app, không ai có thể tìm kiếm hay xem được hồ sơ bạn. Nhưng bạn vẫn chủ động xem được hồ sơ, hình ảnh và kết nối bình thường với người khác. Khi bạn muốn kết nối với ai, thì người đó mới xem ngược lại được hồ sơ bạn.</li>

<li>Giúp bạn chủ động quyết định ai được quyền xem hồ sơ hình ảnh của mình và ai không được xem.</li>

<li>Hiện chức năng này chỉ áp dụng với Gói VIP và Gói SIÊU VIP</li>
<div style={{ display: 'flex', justifyContent: 'center' }}>
  <img src={Image10} alt="Hướng dẫn truy cập Zalo" style={{ width: '80%', margin: '20px 0' }} />
</div>
           
        
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Đóng
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
};

export default Banner;
