// import React from "react";
// import BlockTitle from "./BlockTitle";

// import CtaShape1 from "../assets/images/shapes/cta-1-shape-1.png";
// import CtaShape2 from "../assets/images/shapes/cta-1-shape-2.png";
// import CtaMoc1 from "../assets/images/resources/cta-1-moc-1.png";

// const CTAOne = () => {
//   return (
//     <section className="cta-one">
//       <img src={CtaShape1} className="cta-one__bg-shape-1" alt="awesome post" />
//       <img src={CtaShape2} className="cta-one__bg-shape-2" alt="awesome post" />
//       <div className="container">
//         <div className="cta-one__moc wow fadeInLeft" data-wow-duration="1500ms">
//           <img src={CtaMoc1} className="cta-one__moc-img" alt="awesome post" />
//         </div>
//         <div className="row justify-content-end">
//           <div className="col-lg-6">
//             <div className="cta-one__content">
//               <BlockTitle
//                 textAlign="left"
//                 paraText="Giới thiệu"
//                 titleText={`Ông Điệp Hoàng \n - CEO của Nối Group`}
//               />
//               <div className="cta-one__text">
//                 <p>
//                 Sứ mệnh của chúng tôi là xây dựng một cộng đồng kết nối những người độc thân với sự nghiêm túc, văn minh, an toàn và hiệu quả, giúp họ tìm thấy tình yêu chân thành và hạnh phúc lâu bền 
//                 </p>
//               </div>
//               <ul className="list-unstyled cta-one__list">
//                 <li>
//                   <i className="fa fa-check-circle"></i>
//                   Bảo mật và an toàn tuyệt đối với nền tảng Zalo.
//                 </li>
//                 <li>
//                   <i className="fa fa-check-circle"></i>
//                   100% thành viên thật - Tài khoản thật tại app.
//                 </li>
//                 <li>
//                   <i className="fa fa-check-circle"></i>
//                   Tính năng bảo mật đỉnh cao.
//                 </li>
//                 <li>
//                   <i className="fa fa-check-circle"></i>
//                   Hỗ trợ 24/7.
//                 </li>
//               </ul>
//               <a href="#none" className="thm-btn cta-one__btn">
//                 <span>Khám phá thêm</span>
//               </a>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default CTAOne;
// import React, { useState } from "react";
// import { Modal, Button } from "react-bootstrap";
// import BlockTitle from "./BlockTitle";

// import CtaShape1 from "../assets/images/shapes/cta-1-shape-1.png";
// import CtaShape2 from "../assets/images/shapes/cta-1-shape-2.png";
// import CtaMoc1 from "../assets/images/resources/cta-1-moc-1.png";

// const CTAOne = () => {
//   const [show, setShow] = useState(false);

//   const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);

//   return (
//     <section className="cta-one" id="gioithieu">
//       <img src={CtaShape1} className="cta-one__bg-shape-1" alt="awesome post" />
//       <img src={CtaShape2} className="cta-one__bg-shape-2" alt="awesome post" />
//       <div className="container">
//         <div className="cta-one__moc wow fadeInLeft" data-wow-duration="1500ms">
//           <img src={CtaMoc1} className="cta-one__moc-img" alt="awesome post" />
//         </div>
//         <div className="row justify-content-end">
//           <div className="col-lg-6">
//             <div className="cta-one__content">
//               <BlockTitle
//                 textAlign="left"
//                 paraText="Giới thiệu"
//                 titleText={`Mr. Điệp Hoàng - CEO của Nối Group`}
//               />
//               <div className="cta-one__text">
//                 <p>
//                   Sứ mệnh của chúng tôi là xây dựng một cộng đồng kết nối những người độc thân với sự nghiêm túc, văn minh, an toàn và hiệu quả, giúp họ tìm thấy tình yêu chân thành và hạnh phúc lâu bền.
//                 </p>
//               </div>
//               <ul className="list-unstyled cta-one__list">
//                 <li>
//                   <i className="fa fa-check-circle"></i> Bảo mật và an toàn  với nền tảng Zalo.
//                 </li>
//                 <li>
//                   <i className="fa fa-check-circle"></i> 100% thành viên thật - Tài khoản thật tại app.
//                 </li>
//                 <li>
//                   <i className="fa fa-check-circle"></i> Tính năng bảo mật đỉnh cao.
//                 </li>
//                 <li>
//                   <i className="fa fa-check-circle"></i> Hỗ trợ 24/7.
//                 </li>
//               </ul>
//               <Button variant="primary" onClick={handleShow} className="thm-btn cta-one__btn">
//                <span>Khám phá thêm</span> 
//               </Button>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* Modal */}
//       <Modal show={show} onHide={handleClose} size="xl">
//         <Modal.Header closeButton>
//           <Modal.Title>Giới thiệu iudi.app</Modal.Title>
//         </Modal.Header>
//         <Modal.Body style={{ maxHeight: '600px', overflowY: 'auto' }}>
         
//           <p><strong>Tìm Kiếm Tình Yêu Chân Thực Cùng Ứng Dụng Hẹn Hò "iudi"</strong></p>
//           <p>HÃY YÊU NHAU ĐI – Một Cộng Đồng Kết Nối An Toàn, Văn Minh và Nghiêm Túc</p>
//           <p>
//             Chào mừng bạn đến với iudi, ứng dụng hẹn hò duy nhất tại thời điểm này ở Việt Nam hoạt động trên nền tảng Zalo. 
//             Đây không chỉ là một app hẹn hò, mà là nơi kết nối những tâm hồn đồng điệu với sứ mệnh xây dựng mối quan hệ bền vững và ý nghĩa.
//           </p>
//           <p>
//             Với "iudi," bạn không chỉ tìm thấy tình yêu, mà còn tìm thấy sự an toàn và bảo mật tuyệt đối.
//           </p>

//           <h5>Vì Sao Nên Chọn "iudi"?</h5>
//           <ol>
//             <li><strong>Bảo mật và an toàn tuyệt đối với nền tảng Zalo:</strong> 
//               iudi tận dụng sức mạnh bảo mật từ Zalo, giúp bạn đăng ký và tham gia với tài khoản Zalo cá nhân. Điều này không chỉ giúp bảo vệ quyền riêng tư của bạn mà còn giúp cập nhật các kết nối và tương tác một cách nhanh chóng, tiện lợi.
//             </li>
//             <li><strong>100% thành viên thật - Tài khoản thật tại app:</strong> 
//               Chúng tôi cam kết 100% thành viên thật. Mỗi người tham gia đều được xác thực và xác minh giấy tờ tùy thân, mang đến sự tin cậy tuyệt đối trong từng kết nối. Không còn lo lắng về các tài khoản ảo hay thiếu trung thực.
//             </li>
//             <li><strong>Tính năng bảo mật đỉnh cao:</strong> 
//               Thông tin cá nhân như địa chỉ, số điện thoại, CCCD hay nick Facebook của bạn sẽ được bảo mật tuyệt đối. Những hình ảnh cá nhân chỉ hiển thị cho các thành viên đã đăng ký và được kiểm duyệt bởi Ban Quản Trị.
//             </li>
//             <li><strong>Hỗ trợ 24/7:</strong> 
//               Đội ngũ nhân viên hỗ trợ online 24/7, giải đáp mọi thắc mắc và xử lý các vấn đề phát sinh một cách kịp thời. Bạn có thể yên tâm trải nghiệm và tìm kiếm tình yêu mà không phải lo lắng về bất kỳ vấn đề kỹ thuật nào.
//             </li>
//           </ol>

//           <h5>Một Cộng Đồng Văn Minh, Nghiêm Túc và Hiệu Quả</h5>
//           <p>
//             iudi được điều hành bởi Nối Group, đơn vị đã có nhiều năm kinh nghiệm trong việc xây dựng cộng đồng kết nối độc thân văn minh và nghiêm túc tại Việt Nam. 
//             Với mong muốn mang lại một môi trường hẹn hò an toàn và hiệu quả, chúng tôi không ngừng nỗ lực xây dựng những tính năng hỗ trợ kết nối tốt nhất và đảm bảo sự minh bạch trong mọi tương tác.
//           </p>

//           <h5>Tham Gia "iudi" – Trải Nghiệm Hẹn Hò Thực Sự</h5>
//           <p>
//             Tại iudi, chúng tôi tin rằng ai cũng xứng đáng có được một tình yêu chân thực và mối quan hệ vững chắc. 
//             Đừng bỏ lỡ cơ hội trải nghiệm những cuộc trò chuyện chân thành và những buổi hẹn gặp đầy thú vị. Hãy để iu di trở thành cầu nối giúp bạn tìm thấy tình yêu đích thực. Tham gia ngay hôm nay tại www.iudi.app.
//           </p>

//           <h5>VỀ CHÚNG TÔI</h5>
//           <p>
//             • Công Ty TNHH Nối Group<br />
//             • MST: 0318379051<br />
//             • Tầng 08, Toà nhà Pearl Plaza, Số 561A Điện Biên Phủ, P.25, Q.Bình Thạnh, TP.HCM<br />
//             • Tel: 0866428869<br />
//             • Website: www.noi.dating<br />
//             iu di – Hãy yêu nhau đi!
//           </p>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={handleClose}>
//             Đóng
//           </Button>
//         </Modal.Footer>
//       </Modal>
//     </section>
//   );
// };

// export default CTAOne;




import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import BlockTitle from "./BlockTitle";

import CtaShape1 from "../assets/images/shapes/cta-1-shape-1.png";
import CtaShape2 from "../assets/images/shapes/cta-1-shape-2.png";
import CtaMoc1 from "../assets/images/resources/cta-1-moc-1.png";
import Image1 from "../assets/images/resources/henho1.png"; // Add your image imports here
import Image2 from "../assets/images/resources/henho2.jpg"; // Add your image imports here
import Image3 from "../assets/images/resources/henho3.jpg"; // Add your image imports here
import Image4 from "../assets/images/resources/henho4.jpg"; // Add your image imports here
import Image5 from "../assets/images/resources/henho5.jpg"; // Add your image imports here
import Image6 from "../assets/images/resources/henho6.jpg"; // Add your image imports here
import Image7 from "../assets/images/resources/henho7.jpg"; // Add your image imports here
import Image8 from "../assets/images/resources/henho8.jpg"; // Add your image imports here
import Image9 from "../assets/images/resources/henho9.jpg"; // Add your image imports here
import Image10 from "../assets/images/resources/henho10.jpg"; // Add your image imports here
import note1 from "../assets/images/resources/note1.jpg"; // Add your image imports here
import note2 from "../assets/images/resources/note2.jpg"; // Add your image imports here
import note3 from "../assets/images/resources/note3.jpg"; // Add your image imports here
const HuongDan = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <section className="cta-one" id="gioithieu">
      <img src={CtaShape1} className="cta-one__bg-shape-1" alt="awesome post" />
      <img src={CtaShape2} className="cta-one__bg-shape-2" alt="awesome post" />
      <div className="container">
        <div className="cta-one__moc wow fadeInLeft" data-wow-duration="1500ms">
          <img src={CtaMoc1} className="cta-one__moc-img" alt="awesome post" />
        </div>
        <div className="row justify-content-end">
          <div className="col-lg-6">
            <div className="cta-one__content">
              <BlockTitle
                textAlign="left"
                paraText="Giới thiệu"
                titleText={`Mr. Điệp Hoàng - CEO của Nối Group`}
              />
              <div className="cta-one__text">
                <p>
                  Sứ mệnh của chúng tôi là xây dựng một cộng đồng kết nối những người độc thân với sự nghiêm túc, văn minh, an toàn và hiệu quả, giúp họ tìm thấy tình yêu chân thành và hạnh phúc lâu bền.
                </p>
              </div>
              <ul className="list-unstyled cta-one__list">
                <li><i className="fa fa-check-circle"></i> Bảo mật và an toàn với nền tảng Zalo.</li>
                <li><i className="fa fa-check-circle"></i> 100% thành viên thật - Tài khoản thật tại app.</li>
                <li><i className="fa fa-check-circle"></i> Tính năng bảo mật đỉnh cao.</li>
                <li><i className="fa fa-check-circle"></i> Hỗ trợ 24/7.</li>
              </ul>
              <Button variant="primary" onClick={handleShow} className="thm-btn cta-one__btn">
                <span>Khám phá thêm</span>
              </Button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Hướng dẫn sử dụng</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: '600px', overflowY: 'auto' }}>
         

        
        <div style={{ display: 'flex', justifyContent: 'center' }}>
  <img src={note1} alt="Hướng dẫn truy cập Zalo" style={{ width: '30%', margin: '20px 0' }} />
</div>
        <h4>1. Hướng dẫn truy cập app từ Zalo</h4>
          <p>
          Truy cập ứng dụng Zalo, nhập từ khóa “iudi” trên thanh tìm kiếm hoặc trong mục “Khám phá”, sau đó nhấp vào kết quả mini app “Ứng dụng hẹn hò – iudi” được hiển thị trên màn hình.
          </p>
          <img src={Image1} alt="Hướng dẫn truy cập Zalo" style={{ width: '100%', margin: '20px 0' }} />
          <div style={{ display: 'flex', justifyContent: 'center' }}>
  <img src={note2} alt="Hướng dẫn truy cập Zalo" style={{ width: '30%', margin: '20px 0' }} />
</div>
          <h4>2. Hướng dẫn ghim app ra màn hình điện thoại</h4>
          <p>
            Để thuận tiện cho các lần truy cập về sau một cách nhanh chóng, bạn hãy tạo phim tắt ghim trực tiếp app ra màn hình chính điện thoại của mình.
          </p>
          <ol>
            <li>
              <strong>Bước 1:</strong> Vào trang chủ của app iudi chọn menu có 3 dấu chấm kích vào đó.

            </li>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
  <img src={Image2} alt="Hướng dẫn truy cập Zalo" style={{ width: '50%', margin: '20px 0' }} />
</div>


            <li>
              <strong>Bước 2:</strong> Một bảng điều khiển menu chức năng sẽ hiện ra và bạn hãy chọn mục “Tạo phím tắt” để ghim app iudi ra màn hình điện thoại của mình.
            </li>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
  <img src={Image3} alt="Hướng dẫn truy cập Zalo" style={{ width: '50%', margin: '20px 0' }} />
</div>


<h4>LƯU Ý:</h4>

<h5>1. Với hệ điều hành IOS </h5>

<p>- Điện thoại iphone/ipad của bạn phải đặt safari là trình duyệt mặc định</p>

<p>- Hoặc bạn có thể thao tác thủ công tự mở trình duyệt safari lên, sau đó copy đường dẫn này: (https://zalo.me/s/335985251439518648/) 

đây là đường dẫn của app iudi và sau đó paste vào thanh địa chỉ website trên safari là được.</p>

<p>- Làm theo video hướng dẫn để hoàn tất ghim ứng dụng ra màn hình chính điện thoại</p>

<div>
    
  </div>
  <div style={{ display: 'flex', justifyContent: 'center' }}>
  <img src={Image4} alt="Hướng dẫn truy cập Zalo" style={{ width: '50%', margin: '20px 0' }} />
</div>





<p>- Mọi người có thể chính sửa lại tên “Ứng dụng hẹn hò - iudi” thành tên “iudi” hoặc "App iudi" để khi hiện trên màn hình điện thoại được đẹp hơn.</p>


 <div style={{ display: 'flex', justifyContent: 'center' }}>
  <img src={Image5} alt="Hướng dẫn truy cập Zalo" style={{ width: '50%', margin: '20px 0' }} />
</div>


<div style={{ display: 'flex', justifyContent: 'center' }}>
  <img src={Image6} alt="Hướng dẫn truy cập Zalo" style={{ width: '50%', margin: '20px 0' }} />
</div>



<h5>2. Với hệ điều hành Android </h5>

<p>- Đối với một số dòng máy, khi bạn bấm vào “Tạo phím tắt” sẽ tự động ghim app ra màn hình chính của điện thoại.</p>

<p>- Nhưng cũng có các dòng máy do tuỳ biến của một số hãng, ứng dụng Zalo cần được người dùng chủ động cấp quyền để có thể thêm Shortcut/ghim app ra màn hình chính.</p>

<p>Các bước để chủ động cấp quyền bao gồm:</p>

<p>1 - Nhấn giữ biểu tượng ứng dụng Zalo.</p>

<p>2 - Từ menu popup, chọn App Info/Thông tin ứng dụng</p>

<p>3 - Truy cập mục Quyền ứng dụng/Permissions
 bật quyền Desktop shortcuts/Lối tắt màn hình chính</p>


 <div style={{ display: 'flex', justifyContent: 'center' }}>
  <img src={Image7} alt="Hướng dẫn truy cập Zalo" style={{ width: '50%', margin: '20px 0' }} />
</div>
  
<div style={{ display: 'flex', justifyContent: 'center' }}>
  <img src={Image8} alt="Hướng dẫn truy cập Zalo" style={{ width: '50%', margin: '20px 0' }} />
</div>
  
<div style={{ display: 'flex', justifyContent: 'center' }}>
  <img src={Image9} alt="Hướng dẫn truy cập Zalo" style={{ width: '50%', margin: '20px 0' }} />
</div>

<p>(Lưu ý: Giao diện thiết lập cũng như các bước có thể khác nhau, tuỳ thuộc vào phiên bản Android tuỳ biến đang được sử dụng ở các dòng máy khác nhau)</p>
          </ol>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
  <img src={note3} alt="Hướng dẫn truy cập Zalo" style={{ width: '30%', margin: '20px 0' }} />
</div>
<h5>Bảo mật hình ảnh 2 lớp</h5> 
           <ul>

<li>Thành viên khi chưa đăng ký tài khoản (khách truy cập) khi vào app không thể xem được hình ảnh của bạn. </li>

<li>Thành viên đã có tài khoản và đã được BQT xét duyệt chính thức tham gia app, sẽ xem được hình ảnh của bạn. Nhưng dưới dạng hình ảnh bị làm mờ đi.</li>

<li>Và khi cả 2 bạn cùng bấm quan tâm nhau, lúc đó người kia mới xem được hình ảnh rõ nét của bạn.</li>
</ul>


<h5>Công cụ tìm kiếm chuyên sâu và chính xác</h5>

<li>Tùy vào mỗi gói hồ sơ đăng ký sử dụng tại app, mà bạn sẽ nhận được các quyền lợi tìm kiếm theo các hạng mục tiêu chí riêng.</li>

<li>Công cụ tìm kiếm giúp chọn lựa, sàng lọc nhóm đối tượng phù hợp theo mong muốn của bạn như: Tìm theo nơi ở, tìm theo quê quán, tìm theo tôn giáo, tìm theo nghề nghiệp…</li>



<h5>Chát qua Zalo chưa biết số điện thoại</h5>

<li>Khi hai bạn cùng bấm “Quan tâm” nhau, cả 2 sẽ được kết nối chát qua Zalo cá nhân. Nhưng hoàn toàn chưa biết số điện thoại của nhau.</li>

<li> Điều này giúp bạn chủ động kiểm soát các thông tin riêng tư của mình. Quyết định chia sẻ với ai và không chia sẻ với ai.</li>



<h5>Tính năng tự “Tự Ẩn hồ sơ” của bạn</h5>

<li>Tự ẩn hồ sơ của bạn không hiển thị công khai trên app, không ai có thể tìm kiếm hay xem được hồ sơ bạn. Nhưng bạn vẫn chủ động xem được hồ sơ, hình ảnh và kết nối bình thường với người khác. Khi bạn muốn kết nối với ai, thì người đó mới xem ngược lại được hồ sơ bạn.</li>

<li>Giúp bạn chủ động quyết định ai được quyền xem hồ sơ hình ảnh của mình và ai không được xem.</li>

<li>Hiện chức năng này chỉ áp dụng với Gói VIP và Gói SIÊU VIP</li>
<div style={{ display: 'flex', justifyContent: 'center' }}>
  <img src={Image10} alt="Hướng dẫn truy cập Zalo" style={{ width: '80%', margin: '20px 0' }} />
</div>
           
        
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Đóng
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
};

export default HuongDan;
